import { breakpoints } from 'src/styles/breakpoints';
import { white } from 'src/styles/colors';
import styled, { css, keyframes } from 'styled-components';

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};

  h3 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 90vh;
      padding: 40px 24px;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;
      overflow: auto;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 64.5px;
      }
    `
  )}
`
export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`
